<template>
  <BaseList
    :list-query="parishes"
    :completions-query="completions"
    route="parish"
    locale-section="pages.parishes"
  >
    <template v-slot:extra="{ element: { hundred, municipality } }">
      (<template v-if="hundred.division.hundred"
        >{{ hundred.hundred }}/</template
      >
      <template v-if="hundred.division.province"
        >{{ hundred.province.province }}/</template
      >
      <template
        v-if="
          hundred.province.country.country !==
            municipality.county.country.country
        "
        >{{ hundred.province.country.country }}/</template
      >
      <template v-if="hundred.division.municipality"
        >{{ municipality.municipality }}/</template
      >
      <template v-if="hundred.division.county"
        >{{ municipality.county.county }}/</template
      >{{ municipality.county.country.sv }})
    </template>
  </BaseList>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "ParishList",
  components: {
    BaseList: () => import("@/components/BaseList")
  },
  data() {
    return {
      parishes: gql`
        query parishes {
          list: parishes {
            id
            name: parish
            parish
            hundred {
              hundred
              province {
                province
                country {
                  country
                  sv
                }
              }
              division {
                hundred
                province
                municipality
                county
              }
            }
            municipality {
              municipality
              county {
                county
                country {
                  country
                  sv
                }
              }
            }
          }
        }
      `,
      completions: gql`
        query findByParish($search: String!) {
          completions: findByParish(parish: $search) {
            name: parish
            id
          }
        }
      `
    };
  }
};
</script>
